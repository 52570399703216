<template>
  <div>
    <div>
      <div class="columns" style="width: 1100px;margin:0 auto;">
        <div class="column is-one-fifth">
          <img src="https://s3.ax1x.com/2021/03/07/6KpeY9.png" width="120"/>
        </div>
        <div class="column">
          <p style="font-weight: bold; margin-bottom: 10px; margin-top: 10px;">
            <span>《码农翻身》</span>
            <span>刘欣(著)</span>
            <span>[2018-6-1]</span>
          </p>
          <div class="columns">
            <div class="column is-one-quarter">
              <p style="margin-bottom: 10px;">状态:
                <b-tag type="is-warning">在读</b-tag>
              </p>
              <p style="margin-bottom: 10px;">个人评分:
                <b-rate
                    :value="4.5"
                    :max="5"
                    disabled
                    show-score>
                </b-rate>
              </p>
              <p style="margin-bottom: 10px;"><a>相关笔记</a></p>
            </div>
            <div class="column">
              <p>个人评价:</p>
              <p>作为一本非常棒的<b>科普类</b>技术图书，用讲故事的形式介绍了很多编程技术知识和计算机底层原理。
                至少对我来说，我在这本书里面了解到了很多基本概念和原理，对如何深入学习它们有了更具体的方向。</p>
              <p>当然，我认为这本书读起来很爽的另一个原因就是里面很多概念其实之前已经或多或少有了解过，所以读起来并不费劲，
              基本上可以一节一节地过，而读完又会有了新的认识。</p>
              <p>总之，很赞！</p>
            </div>
          </div>
        </div>
      </div>
      <!--        <b-tag type="is-success">已读</b-tag>-->
      <!--        <b-tag type="is-warning">在读</b-tag>-->
      <!--        <b-tag type="is-info">想读</b-tag>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "BookshelfPage"
}
</script>

<style scoped>
.m-box {
  display: flex;
  justify-content: center; /* 水平居中 */
  width: 1000px;
  height: 600px;
  border: 1px solid red;
}
.m-inner {
  width: 300px;
  height: 200px;
  background-color: red;
}
</style>
